@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:100,300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

::-webkit-scrollbar{
  background-color: transparent;
  position: relative;
  top:100px;
  width: 5px;
}
::-webkit-scrollbar-thumb{
  background-color: black;
  border:1px solid rgba(255, 255, 255, .2);
  border-radius: 5px;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
*{margin: 0;padding: 0;}

h1, h2, h3, h4, h5, h6{
  font-family: 'Alegreya Sans SC', sans-serif;
  letter-spacing: 1px;
}
p, span{
  font-family: 'roboto', sans-serif;
}
body{text-align: center;}

$app-bg: rgb(218, 218, 218);
$game-bg: rgb(205, 205, 205);
$tile-dark: rgb(20, 20, 20);
$dark-grey:rgb(17, 17, 17);

.drawer{
  *{padding: 10px 0px; text-decoration: none; color: inherit; font-family: Montserrat;}
  text-align: center;
  h1:not(:first-child){border-top: 1px solid white;}
  h1{
    border-bottom: 1px solid white;
    padding: 5vh;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 1px;
  }
  p{
    cursor: pointer;
    margin: 1px 0;
    padding: 15px 0;
    width: 100%;
    transition: all .3s;
    &:hover{
      background-color: rgb(31, 31, 31);
      transition: all .3s;
    }
  }
}

.app{
  @include flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $app-bg;
  position: fixed;
}
.game{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 500px;
  height: 500px;
  padding: 1px;
  background-color: $game-bg;
  box-shadow: 0px 0px 15px 0px black;
  .tile{
    @include flex;
    flex: 20%;
    margin: 1px;
    list-style-type: none;
    div{
      flex: 20%;
      @include flex;
      height: 100%;
      font-size: 2.3rem;
      color: white;
      cursor: grab;
      user-select: none;
      transition: all .1s;
      box-sizing: border-box;
      border: 3px solid rgb(30, 30, 30);
      font-family: 'roboto', sans-serif;
      background-color: $tile-dark;
      &:active{
        cursor: grabbing;
        border: 3px solid rgba(99, 99, 99, .7);
      }
    }
  }
}
.greeting{
  position: relative;
  @include flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 30px;
  user-select: none;
}
@import './src/common/confetti';
.post-game{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 500px;
  z-index: 100;
  .head{
    flex: 15%;
    position: sticky;
    top:0;
    padding: 15px;
    border-bottom: 1px solid black;
    p{width: 100%;text-transform: capitalize;}
  }
  .leaderboard{
    flex: 85%;
    @include flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    background-color: $game-bg;
    .head{
      padding: 0;
      width: 100%;
      position: sticky;
      top:0;
      background-color: $game-bg;
      h1{ 
        width: 100%; 
        position: sticky;
        padding: 10px 0px;
        top:0
      }
      .titles{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        span:nth-child(1){
          flex: .8;
        }
      }
      span{
        flex: 1;
        text-align: left;
        padding: 10px 0px;
        padding-left: 30px;
        border-bottom: 2px solid black;
      }
    }
    .entries-scroll{
      width: 100%;
      margin-bottom: -15px;
      overflow: scroll;
      .entries{
        display: flex;
        justify-content: space-around;
        span:nth-child(1){
          flex: .8;
        }
        span{
          flex: 1;
          text-align: left;
          padding: 10px;
          padding-left: 30px;
          border-bottom: 1px solid black;
        }
      }
    }
  }
  @import './src/common/loading';
}

.modal-clickout{
  display: none;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .4);
  z-index: 10;
}

#help{
  position: absolute;
  top: 70px;
  color:black;
  font-size: 20px;
}
#nickname{
  color: black;
  text-align: center;
  outline: none;
  border:none;
  padding: 3px;
  padding:  5px 20px;
  font-family: 'roboto', sans-serif;
  border-bottom: 2px solid black;
  background-color: transparent;
  &::placeholder{color: rgba(0, 0, 0, 1);text-align: center;}
}
button{
margin: 20px;
padding: 7px 30px;
border-radius: 7px;
outline: none;
font-size: 17px;
color: white;
background-color: $tile-dark;
border: 1px solid white;
}
.cta{
  cursor: pointer;
}

@media (max-width:500px) {
  .app{
      .navbar{
      overflow: unset;
      h1{
        font-size: 1.5rem;
      }
      .navpop{
        top: 50px;
        right: 0;
        height: 200px;
        width: 100%;
        flex-direction: column;
        background-color: black;
      }
    }
    .game{
      height: 90vw;
      width: 90vw;
      .tile{
        div{
          font-size: 1.8rem;
        }
      }
    }
    .greeting{
      font-size: 25px;
    }
    .post-game{
      height: 90vw;
      h1{font-size: 1.5rem;}
      .head{padding: 5px;}
    .leaderboard{
      .head{
        padding-bottom: 0;
        h1{padding: 5px 0px;}
        .titles{span{
          padding-top: 0px;
        }
        }
      }
      span{
        font-size: .9rem;
        }
        .entries-scroll{
          width: 90vw;
          height: 90vw;
          margin-bottom: -10px;
          overflow: scroll;
          .entries{
            display: flex;
            justify-content: space-around;
            span:nth-child(1){
              flex: .8;
            }
            span{
              flex: 1;
              text-align: left;
              font-size: .9rem;
              padding: 8px;
              padding-left: 30px;
              border-bottom: 1px solid black;
            }
          }
        }
      }
    }
    .modal-clickout{
    display: unset;
    }
    button{
      margin: 10px;
      font-size: 13px;
    }
    #help{
      font-size: 16px;
      top: 70px;
    }
  }
}
